import {Component} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'dsmkd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor() {
  }


  calculateActualYear(): string {
    return moment().format('YYYY');
  }
}
