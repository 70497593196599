<div *ngIf="legalPerson" nxRow rowJustify="center" class="nx-margin-top-2m">
  <div nxCol="12">
    <h3 style="font-size: 24px;" nxHeadline="subsection-medium" class="nx-font-weight-semibold nx-margin-bottom-m">
      Meine Allianz: Vertretungsberechtigte Person</h3>
    <nx-formfield label="Vorname">
      <input nxInput required [(ngModel)]="currentAuthRep.firstname"/>
      <nx-error nxFormfieldError>
        Bitte geben Sie den Vornamen der vertretungsberechtigten Person an.
      </nx-error>
    </nx-formfield>
    <nx-formfield label="Nachname">
      <input nxInput required [(ngModel)]="currentAuthRep.lastname"/>
      <nx-error nxFormfieldError>
        Bitte geben Sie den Nachnamen der vertretungsberechtigten Person an.
      </nx-error>
    </nx-formfield>
    <nx-formfield label="Rolle im Unternehmen">
      <nx-dropdown required [(ngModel)]="currentAuthRep.role">
        <nx-dropdown-item *ngFor="let role of roleOptions" [value]="role">{{role}}</nx-dropdown-item>
      </nx-dropdown>
      <nx-error nxFormfieldError>
        Bitte geben Sie die Rolle der vertretungsberechtigten Person im Unternehmen an.
      </nx-error>
    </nx-formfield>
    <h3 style="font-size: 20px;" nxHeadline="subsection-medium" class="nx-font-weight-semibold nx-margin-bottom-m">
      Persönliche Identifikation</h3>
    <nx-checkbox class="nx-margin-bottom-s" [ngModel]="isIdent" required
                 (ngModelChange)="optOutStatusChange($event)" name="sofortIdentChecked">
      Ich habe die hier genannte Person auf ihren Wunsch zur Registrierung zu Meine Allianz eingeladen.
      Dabei habe ich die Personalien und die Vertretungsberechtigung für das Kundenunternehmen überprüft.
    </nx-checkbox>
    <nx-error *ngIf="!isIdent && (touched || showError)" nxFormfieldError>
      Die Identifikation der vertretungsberechtigten Person muss bestätigt werden.
    </nx-error>
  </div>
</div>
