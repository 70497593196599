import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../../core/services/app-state.service';
import {KommDataChangeSummaryType} from '../../model/komm-data-change-summary.model';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {Router} from '@angular/router';
import {PhoneNumberFormatPipe} from '../../../core/pipes/mobile-number-format.pipe';
import {KommDataService} from '../../../core/services/komm-data.service';
import {ErrorType} from '../../model/komm-data-change-response.model';
import {SummaryDataBlock, SummaryDataLine} from '@nxt/ngx-ndbx-extensions/summary';

@Component({
  selector: 'dsmkd-komm-data-summary',
  templateUrl: './komm-data-change-summary.component.html',
  styleUrls: ['./komm-data-change-summary.component.scss']
})
export class KommDataChangeSummaryComponent implements OnInit {

  summaryDataBlock: SummaryDataBlock = [];
  showError: boolean;
  showEmailAlreadyExistsError: boolean;

  constructor(
    private readonly appStateService: AppStateService,
    private readonly scrollingService: ScrollingService,
    private readonly mobileNumberFormatPipe: PhoneNumberFormatPipe,
    private readonly kommDataService: KommDataService,
    private readonly router: Router) {
  }


  ngOnInit(): void {
    this.scrollingService.scrollToTop();
    const kommDataChangeSummaryModel = this.appStateService.kommDataChangeSummary;
    const newEmailChangeSummaryItem = kommDataChangeSummaryModel.kommdataChangeEmails
      .find(email => email.type === KommDataChangeSummaryType.NEW);
    if (newEmailChangeSummaryItem) {
      this.summaryDataBlock.push(
        {
          key: 'Bisherige E-Mail-Adresse',
          value: kommDataChangeSummaryModel.kommdataChangeEmails
            .find(email => email.type === KommDataChangeSummaryType.OLD)?.item?.value
        } as SummaryDataLine
      );
      this.summaryDataBlock.push(
        {
          key: 'Neue E-Mail-Adresse',
          value: newEmailChangeSummaryItem.item?.value
        } as SummaryDataLine
      );
    }
    const newMobileNumberChangeSummaryItem = kommDataChangeSummaryModel.kommdataChangeMobileNumbers
      .find(email => email.type === KommDataChangeSummaryType.NEW);
    if (newMobileNumberChangeSummaryItem) {
      this.summaryDataBlock.push(
        {
          key: 'Bisherige Mobilfunknummer',
          value: this.mobileNumberFormatPipe.transform(kommDataChangeSummaryModel.kommdataChangeMobileNumbers
            .find(email => email.type === KommDataChangeSummaryType.OLD)?.item?.value
          )
        } as SummaryDataLine
      );
      this.summaryDataBlock.push(
        {
          key: 'Neue Mobilfunknummer',
          value: this.mobileNumberFormatPipe.transform(newMobileNumberChangeSummaryItem?.item?.value)
        } as SummaryDataLine
      );
    }
  }

  returnToChangeKommData() {
    this.router.navigate(['change', '']);
  }

  onSubmit() {
    const kommDataChangeSummaryModel = this.appStateService.kommDataChangeSummary;
    const kommDataChangeBackendModel = {
      newMazEmail: kommDataChangeSummaryModel.kommdataChangeEmails.find(email => email.type === KommDataChangeSummaryType.NEW)?.item,
      newMazMobileNumber: kommDataChangeSummaryModel.kommdataChangeMobileNumbers.find(email => email.type === KommDataChangeSummaryType.NEW)?.item
    };

    this.kommDataService.postChangeMAZKommData(kommDataChangeBackendModel).subscribe(
      res => {
        const body = res.body;
        if (body) {
          if (body.errorType && ErrorType[body.errorType as unknown as keyof ErrorType] === ErrorType.MAZ_ALREADY_EXISTS) {
            this.showEmailAlreadyExistsError = true;
          } else if ((body.emailChangeSuccess === false && body.mobileChangeSuccess === false)
            || (kommDataChangeBackendModel.newMazEmail === undefined && body.mobileChangeSuccess === false)
            || (kommDataChangeBackendModel.newMazMobileNumber === undefined && body.emailChangeSuccess === false)) {
            this.showError = true;
            this.showEmailAlreadyExistsError = false;
          } else {
            const response = this.appStateService.kommDataChangeResponse;
            response.emailChangeSuccess = kommDataChangeBackendModel.newMazEmail ? body.emailChangeSuccess : undefined;
            response.mobileChangeSuccess = kommDataChangeBackendModel.newMazMobileNumber ? body.mobileChangeSuccess : undefined;
            this.router.navigate(['change', 'success']);
          }
        }
      },
      (error) => {
        this.showError = true;
        this.showEmailAlreadyExistsError = false;
      });
  }
}
