<div nxModalContent>
  <div class="text-align-center">
    <nx-icon name="check" size="s" outline="true"
             class="text-align-center nx-margin-bottom-m" style="color: var(--accent-02)"></nx-icon>
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-m">
      {{'feedback.done.headline.title' | translate}}
    </h3>
    <p nxCopytext class="nx-font-weight-light">
      {{'feedback.done.headline.description' | translate}}
    </p>
  </div>

  <button nxButton="tertiary"  type="button" nxModalClose>
    {{'feedback.done.link.close' | translate}}
  </button>

</div>
