<div class="nx-margin-top-m">
  <p nxCopytext="normal">Sie haben Verbesserungsvorschläge?
    <nx-link><a (click)="openFeedbackModal()" (keyup.enter)="openFeedbackModal()" tabIndex="0">Feedback geben</a>
    </nx-link>
  </p>

  <ng-template #feedbackModalBody>
    <ng-container>
      <div class="feedback-modal">
        <div class="text-align-center">
          <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-m">
            Feedback geben
          </h3>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" id="feedback-msg-form">
          <div nxLayout="grid">
            <div nxRow="">
              <div nxCol="12">
                <dsmkd-textarea
                  label="Was können wir an diesem Service verbessern?"
                  [textAreaFormControl]="controls.message"
                  maxCharacterCount="5000">
                </dsmkd-textarea>
              </div>
            </div>
            <div *ngIf="error400" nxRow class="margin-bottom-12">
              <div nxCol="12">
                <nx-error nxFormfieldError>
                  Ihre Daten sind nicht valide. Bitte überprüfen Sie Ihre Eingabe.
                </nx-error>
              </div>
            </div>

            <div nxRow="">
              <div nxCol="12">
                <nx-checkbox formControlName="contactInformation">Bei Rückfragen dürfen wir Sie kontaktieren (optional)
                </nx-checkbox>
              </div>
            </div>

            <div nxRow="" *ngIf="controls?.contactInformation?.value">
              <div nxCol="10">
                <div class="padding-left-checkbox">
                  <p nxCopytext>Wir berücksichtigen jedes Feedback, können jedoch bei der Vielzahl der Rückmeldungen
                    nicht in jedem Fall eine individuelle Antwort garantieren.</p>
                  <nx-formfield label="E-Mail-Adresse (optional)">
                    <input nxInput [formControl]="controls.email">
                    <nx-error nxFormfieldError>
                      Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </nx-error>
                  </nx-formfield>
                  <nx-formfield label="Telefonnummer (optional)">
                    <input nxInput [formControl]="controls.phone">
                  </nx-formfield>
                </div>
              </div>
            </div>

            <div nxRow="">
              <div nxCol="12">
                <nx-message context="info">Kundenanliegen, die an den Innendienst gerichtet sind, können an dieser
                  Stelle nicht bearbeitet werden.
                </nx-message>
              </div>
            </div>

            <div nxRow="">
              <div nxCol="12" style="text-align: right;" class="nx-margin-top-2m link-text-center-small">
                <button nxButton="tertiary"  type="button" (click)="closeFeedback()"
                        class="nx-margin-bottom-2xs link-text-center-small nx-margin-right-s">
                  ABBRECHEN
                </button>
                <button type="submit" nxButton="primary" class="nx-margin-bottom-2xs">Absenden
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
    </ng-container>
  </ng-template>
</div>
