import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private readonly DEV_SERVICE_HUB_URL_REGEX = new RegExp('^https?://(dss-dev.allianz.de)', 'i');
  private readonly UAT_SERVICE_HUB_URL_REGEX = new RegExp('^https?://(dss-uat.allianz.de)', 'i');
  private readonly PROD_SERVICE_HUB_URL_REGEX = new RegExp('^https?://(dss.allianz.de)', 'i');

  private readonly LOCALHOST_SERVICE_HUB_REDIRECT_URL = 'http://localhost:18000';
  private readonly DEV_SERVICE_HUB_REDIRECT_URL = 'https://dss-dev.allianz.de';
  private readonly UAT_SERVICE_HUB_REDIRECT_URL = 'https://dss-uat.allianz.de';
  private readonly PROD_SERVICE_HUB_REDIRECT_URL = 'https://dss.allianz.de';

  getUrl(): string {
    return window.location.href;
  }

  isTeststageURL(): boolean {
    const windowUrl = this.getUrl();
    let isLocalOrTeststage = false;
    if (this.DEV_SERVICE_HUB_URL_REGEX.test(windowUrl) || this.UAT_SERVICE_HUB_URL_REGEX.test(windowUrl)) {
      isLocalOrTeststage = true;
    }
    return isLocalOrTeststage;
  }

  getServiceHubUrlForRedirect(): string {
    const windowUrl = this.getUrl();
    let serviceHubUrl = this.LOCALHOST_SERVICE_HUB_REDIRECT_URL;
    if (this.DEV_SERVICE_HUB_URL_REGEX.test(windowUrl)) {
      serviceHubUrl = this.DEV_SERVICE_HUB_REDIRECT_URL;
    } else if (this.UAT_SERVICE_HUB_URL_REGEX.test(windowUrl)) {
      serviceHubUrl = this.UAT_SERVICE_HUB_REDIRECT_URL;
    } else if (this.PROD_SERVICE_HUB_URL_REGEX.test(windowUrl)) {
      serviceHubUrl = this.PROD_SERVICE_HUB_REDIRECT_URL;
    }
    return serviceHubUrl;
  }
}
