import {Component, OnInit} from '@angular/core';
import {HeaderUserDetails} from '../../../core/models/header-user-details.model';
import {ModelService} from '../../../core/services/model.service';

@Component({
  selector: 'dsmkd-header-user-details',
  templateUrl: './header-user-details.component.html',
  styleUrls: ['./header-user-details.component.scss']
})
export class HeaderUserDetailsComponent implements OnInit {
  headerUserDetails: HeaderUserDetails;

  constructor(
    private readonly modelService: ModelService,
  ) {
  }

  ngOnInit() {
    this.modelService.getHeaderUserDetailsObsv().subscribe((personName) => {
      this.headerUserDetails = personName;
    });
  }
}

