import {Component, OnInit} from '@angular/core';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {AppStateService} from '../../../core/services/app-state.service';
import {UserGroupModel} from '../../../core/services/auth/model/user-group.model';
import {AgentService} from '../../../core/services/auth/agent.service';
import {RedirectService} from '../../../core/services/redirect.service';

@Component({
  selector: 'dsmkd-komm-data-change-success',
  templateUrl: './komm-data-change-success.component.html',
  styleUrls: ['./komm-data-change-success.component.scss']
})
export class KommDataChangeSuccessComponent implements OnInit {

  isEmailChangeSuccessful: boolean = true;
  isMobileChangeSuccessful: boolean = true;
  isCustomerFromSDW: boolean = false;

  constructor(private readonly redirectService: RedirectService,
              private readonly appStateService: AppStateService,
              private readonly agentService: AgentService,
              private readonly scrollingService: ScrollingService) {
  }

  ngOnInit(): void {
    this.isEmailChangeSuccessful = this.appStateService.kommDataChangeResponse.emailChangeSuccess;
    this.isMobileChangeSuccessful = this.appStateService.kommDataChangeResponse.mobileChangeSuccess;
    this.scrollingService.scrollToTop();
    this.isCustomerFromSDW = UserGroupModel.VTNOW === this.agentService.getUserGroup();
  }

  navigateToServiceHub() {
    this.redirectService.doServiceHubRedirect('');
  }
}
