<div>
  <h2 nxHeadline="subsection-medium" class="nx-font-weight-semibold nx-margin-bottom-m">
    Programmteilnahmen
  </h2>

  <h3 *ngIf="mazAlreadyActive || isWeweActiveAndShown() || isDeweActiveAndShown()" nxHeadline="subsection-small">
    Aktive Programmteilnahmen und erteilte Einwilligungen
  </h3>

  <div nxRow *ngIf="isDeweActiveAndShown()">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-bottom-s">
        <span class="nx-font-weight-bold">dEWE</span>
        – Dateneinwiligungserklärung
      </p>
    </div>
  </div>

  <div nxRow *ngIf="isWeweActiveAndShown()">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-bottom-s">
        <span class="nx-font-weight-bold">wEWE</span>
        – Werbeeinwilligungserklärung
      </p>
    </div>
  </div>

  <div nxRow *ngIf="mazAlreadyActive">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal"><span class="nx-font-weight-bold">Meine Allianz</span></p>
    </div>
  </div>

  <div *ngIf="isWeweInactiveAndShown() || !mazAlreadyActive || isDeweInactiveAndShown()">
    <h3 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold">
      Zu Programmen einladen (optional)
    </h3>
  </div>

  <div *ngIf="showErrorMissingKommData">
    <nx-message context="error" class="nx-margin-top-0 nx-margin-bottom-m">
      <span class="nx-font-weight-bold">Fehlende Kontaktdaten</span><br/>
      Um die Einladung zu versenden, geben Sie bitte sowohl eine E-mail-Adresse als auch eine Mobilfunknummer an.
    </nx-message>
  </div>

  <nx-checkbox
    *ngIf="noProgramActive()"
    [(checked)]="allProgramsChecked"
    class="nx-margin-bottom-s">
    Einladung zu allen Programmen senden
  </nx-checkbox>

  <div *ngIf="isDeweInactiveAndShown()" nxRow>
    <div nxCol="1">
      <div *ngIf="allProgramsChecked; else checkDEWE">
        <nx-icon size="s" name="product-check" class="dsmkd-check-icon"></nx-icon>
      </div>
      <ng-template #checkDEWE>
        <nx-icon size="s" name="product-check" class="dsmkd-check-icon"></nx-icon>
      </ng-template>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">dEWE</span> – Dateneinwilligungserklärung
      </p>
    </div>
  </div>

  <div *ngIf="isDeweInactiveAndShown()" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Kunden-/Interessentendaten dürfen von der Agentur verarbeitet und langfristig gespeichert werden.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Die Dateneinwilligungserklärung für die Agentur (dEWE) wird benötigt,
          um Kunden- oder Interessentendaten im Rahmen eines
          Beratungsgespräches zu verarbeiten und langfristig zu speichern. Die
          dEWE wird getrennt und unabhängig von der wEWE eingeholt.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="isWeweInactiveAndShown()" nxRow>
    <div nxCol="1">
      <div *ngIf="allProgramsChecked; else  weweInactiveIcon">
        <nx-icon size="s" name="product-check" class="dsmkd-check-icon"></nx-icon>
      </div>
      <ng-template #weweInactiveIcon>
        <div *ngIf="dEWEFeatureFlagActive; else weweInactiveDeweFeatureFlagNotActive">
          <nx-checkbox *ngIf="isWeweInactiveAndShown() && !allProgramsChecked" [(checked)]="checkedWEWE">
          </nx-checkbox>
        </div>
      </ng-template>
      <ng-template #weweInactiveDeweFeatureFlagNotActive>
        <nx-icon size="s" name="product-cross" class="dsmkd-close-icon"></nx-icon>
      </ng-template>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">wEWE</span> – Werbeeinwilligungserklärung
      </p>
    </div>
  </div>
  <div *ngIf="isWeweInactiveAndShown()" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Vertriebliche Ansprache durch Agentur und die Gesellschaft wird gestattet.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Die Werbeeinwilligungserklärung (wEWE) ermöglicht die
          vertriebliche Ansprache von Kund:innen oder Interessent:innen durch Vetreter:innen
          und die Gesellschaft. Sie ist daher ein wichtiges Element für unser vertriebliches Wachstum.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!mazAlreadyActive" nxRow>
    <div nxCol="1">
      <div *ngIf="allProgramsChecked">
        <div *ngIf="allProgramsChecked && (hasAddress || legalPerson); else mazInactiveIcon">
          <nx-icon id="program-overview-maz-selectable" size="s" name="product-check"
                   class="dsmkd-check-icon"></nx-icon>
        </div>
        <ng-template #mazInactiveIcon>
          <nx-icon id="program-overview-maz-not-selectable" size="s" name="product-cross"
                   class="dsmkd-close-icon"></nx-icon>
        </ng-template>
      </div>
      <nx-checkbox *ngIf="!mazAlreadyActive && !allProgramsChecked" [(checked)]="checkedMaz">
      </nx-checkbox>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">Meine Allianz</span>
      </p>
    </div>
  </div>
  <div *ngIf="!mazAlreadyActive" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          In Meine Allianz (MAZ) finden Kund:innen alles rund um ihre Verträge mit der Allianz.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Im Meine Allianz (MAZ) Portal finden Kund:innen alles rund um ihre Verträge mit der Allianz.
          Sie als Vertreter:in erhalten über MAZ passgenaue Leads Ihrer Kund:innen
          und können über das MAZ Postfach direkt mit Ihren Kund:innen kommunizieren.
        </p>
      </div>
      <nx-message *ngIf="shouldShowMissingAddressHint()" context="warning">
        <span id="maz-hint-address-not-available" class="nx-font-weight-bold">Fehlende Postanschrift: Keine MAZ-Einladung möglich</span><br/>
        Bitte ergänzen Sie zuerst in AMIS.NOW eine Adresse, um anschließend über diesen Service zu Meine Allianz
        einzuladen.
      </nx-message>
    </div>
  </div>

</div>

<div *ngIf="showIdentNatPerson()">
  <div nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="section-divider padding-left-0">
      <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold nx-margin-top-m">
        Vereinfachte Meine Allianz Freischaltung für {{ personName }}
      </h4>

      <nx-checkbox [(checked)]="checkedPersonalIdentification" class="nx-margin-bottom-s">
        Ich habe {{ personName }} anhand eines Ausweisdokuments identifiziert
      </nx-checkbox>
      <p nxCopytext="normal">
        Mit dieser Identifikation durch Sie kann nach der Anmeldung sofort auf
        Meine Allianz und eigene Verträge zugegriffen werden.
        Alternativ erfolgt die Identifikation im Anmeldeprozess
        per Brief-Code oder Sofortidentifizierung.
      </p>
    </div>
  </div>
</div>

<div *ngIf="!mazAlreadyActive || isWeweInactiveAndShown()" nxRow>
  <button
    (click)="toggleAdvancedSettings()"
    nxButton="tertiary"
    type="button">
    <div nxCol="1">
      <nx-icon *ngIf="!advancedSettings" size="s" name="chevron-down" class="dsmkd-chevron-icon"></nx-icon>
      <nx-icon *ngIf="advancedSettings" size="s" name="chevron-up" class="dsmkd-chevron-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <span id="additional-information" *ngIf="!advancedSettings">Erweiterte Informationen und Einstellungen</span>
      <span *ngIf="advancedSettings">zurück zur Kurzübersicht</span>
    </div>
  </button>
</div>

<dsmkd-identified-check *ngIf="checkedMaz || (!mazAlreadyActive && allProgramsChecked)"
                        [legalPerson]="legalPerson"
                        [kundenName]="personName"
                        [(isIdent)]="checkedPersonalIdentification"
                        [(authRep)]="authorizedRepresentative"
></dsmkd-identified-check>
