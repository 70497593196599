import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  DeletableType,
  KommDataItem,
  KommDataModel,
  KommDataType,
  ProgramParticipationItem,
  ProgramParticipationType
} from '../../../core/models/kommdata.model';
import {ModelService} from '../../../core/services/model.service';
import {Router} from '@angular/router';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {NxDialogService} from '@aposin/ng-aquila/modal';
import {KommDataService} from '../../../core/services/komm-data.service';
import {UrlApiService} from '../../../core/services/url-api.service';
import {catchError, EMPTY, mergeMap, switchMap, tap, timer} from 'rxjs';
import {LoadingAnimationService} from '../../../core/services/loading-animation.service';
import {RedirectService} from '../../../core/services/redirect.service';

@Component({
  selector: 'dsmkd-komm-data-edit',
  templateUrl: './komm-data-edit.component.html',
  styleUrls: ['./komm-data-edit.component.scss'],
})
export class KommDataEditComponent implements OnInit {

  emails: KommDataItem[] = [];
  mobileNumbers: KommDataItem[] = [];
  phoneNumbers: KommDataItem[] = [];
  kommDataToDelete: KommDataItem;
  kommDataToDeleteType: string;
  kommDataModel: KommDataModel;

  mazEmail: string;
  mazMobileNumber: string;
  customerName: string;

  trackingSourceDetail = '';
  legalPerson = false;
  @ViewChild('deleteModalBody') feedbackModalTemplate: TemplateRef<any>;

  constructor(private readonly modelService: ModelService,
              private readonly router: Router,
              private readonly redirectService: RedirectService,
              private readonly scrollingService: ScrollingService,
              private readonly dialogService: NxDialogService,
              private readonly kommDataService: KommDataService,
              private readonly urlApiService: UrlApiService,
              private readonly cdr: ChangeDetectorRef,
              private readonly loadingAnimationService: LoadingAnimationService
  ) {
  }

  ngOnInit(): void {
    this.kommDataModel = this.fetchKommData();
    this.updateKommDataLists();
    this.modelService.getHeaderUserDetailsObsv().subscribe((personName) => {
      this.customerName = personName.firstName + ' ' + personName.lastName;
    });
  }

  setMazEmailAndMobileNumber(mazProgramParticipationItem: ProgramParticipationItem): void {
    this.mazEmail = mazProgramParticipationItem.emails ? mazProgramParticipationItem.emails[0]?.value : '';
    this.mazMobileNumber = mazProgramParticipationItem.mobileNumbers ? mazProgramParticipationItem.mobileNumbers[0]?.value : '';
  }

  hasOtherCommunicationData(): boolean {
    return this.emails.length > 0 || this.mobileNumbers.length > 0;
  }

  navigateBack(): void {
    this.redirectService.doServiceHubRedirect('');
  }

  navigateToMazChange(): void {
    this.scrollingService.scrollToTop();
    this.router.navigate(['change', '']);
  }

  navigateToAddKommData(): void {
    this.scrollingService.scrollToTop();
    this.router.navigate(['add', 'digital-profile'], {queryParams: {from: 'edit'}});
  }

  deleteKommItem(kommItem: KommDataItem, type: string): void {
    if (this.isDeleteDisabled(kommItem)) {
      return;
    }
    this.kommDataToDelete = kommItem;
    this.kommDataToDeleteType = type;

    this.dialogService.open(this.feedbackModalTemplate, {
      ariaLabel: 'Delete',
      closeIconButtonLabel: 'Dialog schließen',
      showCloseIcon: true,
      height: '150'
    });
  }

  closeDeleteDialog() {
    this.dialogService.closeAll();
  }

  sendDeleteRequest() {
    this.assignKommDataType();
    this.kommDataService.deleteKommData(this.kommDataToDelete).pipe(
      switchMap((res) => {
        if (res.body.kommdataDeletionSuccessful) {
          this.loadingAnimationService.showLoading.next(true);
          return timer(3000).pipe(
            switchMap(() => this.kommDataService.getKommData(this.urlApiService.getParams().inboundRoute))
          );
        } else {
          throw new Error('Deletion failed');
        }
      }),
      tap((kommDataModel) => {
        this.updateKommDataModel(kommDataModel);
        this.cdr.detectChanges();
        this.loadingAnimationService.showLoading.next(false);
      }),
      mergeMap(() => this.router.navigate(['edit'])),
      catchError((error) => {
        this.router.navigate(['error', {id: 99999}]);
        this.loadingAnimationService?.showLoading.next(false);
        return EMPTY;
      })
    ).subscribe();
  }

  private assignKommDataType() {
    this.kommDataToDelete.kommDataType = KommDataType[this.kommDataToDelete.type as keyof typeof KommDataType];
  }

  updateKommDataModel(kommDataModel: any) {
    this.modelService.setKommData(kommDataModel.body);
    this.kommDataModel = kommDataModel.body;
    this.updateKommDataLists();
    this.dialogService.closeAll();
  }

  updateKommDataLists(): void {
    const programParticipations = this.kommDataModel.participations?.length === 0 ? null : this.kommDataModel.participations;

    if (programParticipations) {
      const mazProgramParticipationItem = programParticipations.filter(program => program.type === ProgramParticipationType.MAZ)[0];
      this.setMazEmailAndMobileNumber(mazProgramParticipationItem);
    }
    this.emails = this.kommDataModel.emails.length === 0 ? [] : this.kommDataModel.emails.filter(kommdataItem => kommdataItem.value !== this.mazEmail);
    this.mobileNumbers = this.kommDataModel.mobileNumbers.length === 0 ? [] : this.kommDataModel.mobileNumbers.filter(kommdataItem => kommdataItem.value !== this.mazMobileNumber);
    this.phoneNumbers = this.kommDataModel.phoneNumbers?.length === 0 ? [] : this.kommDataModel.phoneNumbers?.filter(kommdataItem => kommdataItem.value !== null);

  }

  getTrashIcon(dataItem: KommDataItem): string {
    return !this.isDeleteDisabled(dataItem) ? 'icon-trash' : 'icon-trash-deactivated';
  }

  isDeleteDisabled(dataItem: KommDataItem): boolean {
    return DeletableType.DELETABLE !== dataItem.deletableType;
  }

  getTooltipText(deletableType: DeletableType, kommDataType: string): string {
    let tooltipText = '';
    if (deletableType === DeletableType.PRIO_FLAG) {
      tooltipText = `Die ${kommDataType} ist mit Prio-Kennzeichen hinterlegt. Die Umstellung des Priokennzeichen auf ` +
        'eine andere E-Mail-Adresse kann derzeit nur über AMIS vorgenommen werden.';
    }
    if (deletableType === DeletableType.MARKED_FOR_UPDATE) {
      tooltipText = `Die ${kommDataType} ist bereits für ein Update / Löschung markiert.`;
    }
    if (deletableType === DeletableType.LINKED_WITH_PROGRAMM) {
      tooltipText = `Die ${kommDataType} ist mit einer Programmteilnahme verknüpft.`;
    }
    return tooltipText;
  }

  transformKommDataType(type: string): string {
    switch (type) {
    case 'PRIVATE':
      return 'privat';
    case 'BUSINESS':
      return 'geschäftlich';
    case 'PRIVATEBUSINESS':
      return 'privat/geschäftlich';
    case 'UNKNOWN':
      return 'unbekannt';
    default:
      return 'unbekannt';
    }
  }

  fetchKommData() {
    return this.modelService.getKommData();
  }
}
