import {Injectable} from '@angular/core';
import {WindowRefService} from './window-ref.service';
import {WindowService} from './window.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private readonly windowRef: WindowRefService,
              private readonly windowService: WindowService) {
  }

  public redirect(url: string): void {
    const fe = this.windowRef.nativeWindow.frameElement;
    if (fe) {
      this.windowRef.nativeWindow.top.location.href = url;
    } else {
      this.windowRef.nativeWindow.location.href = url;
    }
  }

  doServiceHubRedirect(urlParameter: string): void {
    this.redirect(this.windowService.getServiceHubUrlForRedirect() + urlParameter);
  }
}
