import {Component} from '@angular/core';
import {KommDataService} from '../../../core/services/komm-data.service';
import {
  DisplayProgramParticipation,
  KommDataAddLink,
  KommDataModel,
  ProgramParticipationItem,
  ProgramParticipationStatus,
  ProgramParticipationType,
  ProgramStatus
} from '../../../core/models/kommdata.model';
import {Router} from '@angular/router';
import {AppStateService} from '../../../core/services/app-state.service';
import {ModelService} from '../../../core/services/model.service';
import {ErrorMessage, Overlay} from '../../../core/models/resilience.model';
import {ProgramUtilService} from '../../util/program-util.service';
import {ConfigurationService} from '../../../core/services/config.service';
import {ConsentService} from '../../../core/services/consent.service';
import {ConsentAddRequest} from '../../../core/models/consent-add-request';
import {ConsentElement, ConsentType} from '../../../core/models/consent-element';
import {AgentService} from '../../../core/services/auth/agent.service';
import {RedirectService} from '../../../core/services/redirect.service';
import {UserGroupModel} from '../../../core/services/auth/model/user-group.model';

@Component({
  selector: 'dsmkd-komm-data-program-participations',
  templateUrl: './komm-data-program-participations.component.html',
  styleUrls: ['./komm-data-program-participations.component.scss']
})
export class KommDataProgramParticipationsComponent {

  readonly GREEN = {
    id: ProgramParticipationStatus.GREEN,
    iconClass: 'icon-green',
    iconName: 'check-circle'
  } as ProgramStatus;
  readonly RED = {
    id: ProgramParticipationStatus.RED,
    iconClass: 'icon-red',
    iconName: 'close-circle'
  } as ProgramStatus;
  readonly GREY = {
    id: ProgramParticipationStatus.GREY,
    iconClass: 'icon-gray',
    iconName: 'minus-circle'
  } as ProgramStatus;
  readonly YELLOW = {
    id: ProgramParticipationStatus.YELLOW,
    iconClass: 'icon-yellow',
    iconName: 'clock'
  } as ProgramStatus;
  readonly UNKNOWN = {
    id: ProgramParticipationStatus.UNKNOWN,
    iconClass: 'icon-gray',
    iconName: 'product-help-question'
  } as ProgramStatus;

  readonly PROGRAM_STATUS = [this.GREEN, this.RED, this.GREY, this.YELLOW, this.UNKNOWN];

  readonly DIGITAL_PROFILE_PATH = 'digital-profile';

  invitationChoices = [];
  isInvitationButtonDisabled: boolean = true;
  isInvitationPossible: boolean = true;

  displayedActivatedProgramParticipations: DisplayProgramParticipation[];
  programInvitations: ProgramParticipationItem[];

  mazEmail: string;
  mazMobileNumber: string;
  showError: boolean;

  constructor(private readonly redirectService: RedirectService,
              private readonly configurationService: ConfigurationService,
              private readonly kommDataService: KommDataService,
              private readonly modelService: ModelService,
              private readonly router: Router,
              private readonly appStateService: AppStateService,
              private readonly consentService: ConsentService,
              private readonly programUtilService: ProgramUtilService,
              private readonly agentService: AgentService) {

    const kommData = this.modelService.getKommData();
    this.checkResilience(kommData);

    const programParticipations = kommData.participations.length === 0 ? null : kommData.participations;

    if (programParticipations) {
      const mazProgramParticipationItem = programParticipations.filter(program => program.type === ProgramParticipationType.MAZ)[0];
      this.setMazEmailAndMobileNumber(mazProgramParticipationItem);

      let possibleProgramParticipations: ProgramParticipationItem[] = programParticipations.filter(program => program.type !== ProgramParticipationType.SBS);
      if (UserGroupModel.VTNOW !== this.agentService.getUserGroup()) {
        possibleProgramParticipations = possibleProgramParticipations.filter(program => program.type !== ProgramParticipationType.DEWE);
      }
      this.setDisplayedActivatedProgramParticipations(possibleProgramParticipations);

      this.setProgramInvitations(possibleProgramParticipations);

      this.isInvitationPossible = this.programInvitations.length > 0;
    }

  }

  private checkResilience(kommData: KommDataModel): void {
    this.routeToResilienceErrorPageForLegalEntityWithMaz(kommData);

    const maxLength = kommData.participations.length;
    if (kommData.kommDataAddLink === KommDataAddLink.PROGRAM_PARTICIPATIONS
      || kommData.participations.filter(participations => participations.status === ProgramParticipationStatus.GREEN).length === maxLength) {
      return;
    }

    this.routeToDigitalProfilePage(kommData);
    this.routeToResilienceErrorPageForMazPendingStatus(kommData);

    this.router.navigate(['add', this.DIGITAL_PROFILE_PATH]);
  }

  private routeToDigitalProfilePage(kommData: KommDataModel): void {
    if (kommData.kommDataAddLink === KommDataAddLink.INLINE || kommData.kommDataAddLink === KommDataAddLink.DIGITAL_PROFILE) {
      this.router.navigate(['add', this.DIGITAL_PROFILE_PATH]);
    }
  }

  navigateToKommDataChange(): void {
    this.router.navigate(['change', '']);
  }

  private routeToResilienceErrorPageForMazPendingStatus(kommData: KommDataModel): void {
    if (kommData.participations.find(participation => participation.type === ProgramParticipationType.MAZ)?.status
      === ProgramParticipationStatus.YELLOW) {
      this.router.navigate(['add', 'resilience'], {
        state: {
          overlay: Overlay.PROGRAM_PARTICIPATION,
          errorMessage: ErrorMessage.OCS_RUNNING
        }
      });
    }
  }

  private routeToResilienceErrorPageForLegalEntityWithMaz(kommData: KommDataModel): void {
    const hasMaz = this.programUtilService.hasMaz(kommData);
    this.modelService.getHeaderUserDetailsObsv().subscribe(value => {
      if (value.legalEntity && hasMaz) {
        this.router.navigate(['add', 'resilience'], {
          state: {
            overlay: Overlay.PROGRAM_PARTICIPATION,
            errorMessage: ErrorMessage.LEGAL_ENTITY
          }
        });
      }
    });
  }

  private setMazEmailAndMobileNumber(mazProgramParticipationItem: ProgramParticipationItem): void {
    this.mazEmail = mazProgramParticipationItem.emails ? mazProgramParticipationItem.emails[0]?.value : '';
    this.mazMobileNumber = mazProgramParticipationItem.mobileNumbers ? mazProgramParticipationItem.mobileNumbers[0]?.value : '';
  }

  private setDisplayedActivatedProgramParticipations(programParticipations: ProgramParticipationItem[]): void {
    const ACTIVATED_PROGRAM_PARTICIPATION_STATUS = [ProgramParticipationStatus.GREEN, ProgramParticipationStatus.YELLOW];

    this.displayedActivatedProgramParticipations = programParticipations
      // Filter DEWE_PLUS to have only one DEWE consent, DEWE_PLUS without DEWE not possible
      .filter(program => program.type !== ProgramParticipationType.MAZ && program.type !== ProgramParticipationType.DEWE_PLUS)
      .filter(program => ACTIVATED_PROGRAM_PARTICIPATION_STATUS.includes(program.status))
      .map(participation => {
        return {
          type: participation.type,
          status: this.PROGRAM_STATUS.find(s => s.id === participation.status)
        } as DisplayProgramParticipation;
      }) || [];
  }

  private setProgramInvitations(programParticipations: ProgramParticipationItem[]): void {
    const INVITABLE_PROGRAM_PARTICIPATION_STATUS = [ProgramParticipationStatus.GREY, ProgramParticipationStatus.RED];
    this.programInvitations = programParticipations
      .filter(program => program.type !== ProgramParticipationType.MAZ)
      .filter(program => {
        if (!this.agentService.hasVTNR()) {
          return program.type !== ProgramParticipationType.DEWE;
        }
        return true;
      }
      )
      .filter(program => INVITABLE_PROGRAM_PARTICIPATION_STATUS.includes(program.status));
  }

  navigateBack(): void {
    this.redirectService.doServiceHubRedirect('');
  }

  setIsInvitationButtonDisabled(): void {
    this.showError = false;
    this.isInvitationButtonDisabled = this.invitationChoices.length === 0;
  }

  onSubmit() {
    this.configurationService.getConfig().subscribe(config => {
      const configResponse = this.appStateService.configResponse;
      configResponse.deweProcessingFeatureFlag = config.deweProcessingFeatureFlag;
      if (config.deweProcessingFeatureFlag && this.agentService.hasVTNR()) {
        this.useNewConsentAddEndpoint();
      } else {
        this.useOldProgramParticipationEndpoint();
      }
    });
  }

  useOldProgramParticipationEndpoint(): void {
    // Deprecated can be removed when deweProcessingFeatureFlag is removed
    this.kommDataService.postProgramParticipations(this.invitationChoices).subscribe(
      res => {
        if (res.body?.successful) {
          this.invitationChoices.forEach(value => this.appStateService.programParticipationTypes.push(value));
          this.router.navigate(['add', 'success']);
        }
      }, error => {
      }, () => {
        this.showError = true;
        this.isInvitationButtonDisabled = true;
      });
  }

  useNewConsentAddEndpoint() {
    const consentAddRequest = {
      consentsToAdd: this.mapInvitationChoicesToConsentElements(this.invitationChoices),
      vtnr: this.agentService.getVTNR()
    } as ConsentAddRequest;
    this.consentService.addConsent(consentAddRequest).subscribe(consentAddResponse => {
      const dEWEPartOfRequest = this.invitationChoices.find(value => ConsentType.DEWE.toString() === value) !== undefined;
      const dEWESuccessful = consentAddResponse.body.consentsAddingSuccessful.find(value => ConsentType.DEWE === value.consentType) !== undefined;
      if ((dEWEPartOfRequest && !dEWESuccessful) || (!dEWEPartOfRequest && consentAddResponse.body.consentsAddingFailed.length > 0)) {
        this.showError = true;
        this.isInvitationButtonDisabled = true;
      } else {
        this.appStateService.consentAddResponse.consentsAddingSuccessful = consentAddResponse.body.consentsAddingSuccessful;
        this.appStateService.consentAddResponse.consentsAddingFailed = consentAddResponse.body.consentsAddingFailed;
        this.router.navigate(['add', 'success']);
      }
    }, () => {
      this.showError = true;
      this.isInvitationButtonDisabled = true;
    });
  }

  private mapInvitationChoicesToConsentElements(invitationChoices: any[]): ConsentElement[] {
    const consentElements = [] as ConsentElement[];
    invitationChoices.forEach(value => {
      consentElements.push({
        consentType: value,
        emailForConsent: this.mazEmail,
        mobileNumberForConsent: this.mazMobileNumber
      } as ConsentElement);
    });
    return consentElements;
  }
}
